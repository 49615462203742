import { Component, Input, OnInit } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-card-transaction-history',
  templateUrl: './card-transaction-history.component.html',
  styleUrls: ['./card-transaction-history.component.scss'],
})
export class CardTransactionHistoryComponent implements OnInit {
  @Input() status = '';
  @Input() date = '';
  @Input() transactionId = 0;
  @Input() totalPrice = 0;
  @Input() totalBeforeDiscount = 0;
  @Input() promoCode: null | string = null;
  @Input() productName = '';
  @Input() paymentUrl = '';
  @Input() paymentMethod = '';
  @Input() vaBankLogo: string = '';
  @Input() vaName: string = '';
  @Input() vaNumber: string = '';

  showQrCode = false;
  showVaModal = false;

  constructor(
    public router: Router,
  ) {}

  ngOnInit(): void {}

  openPayment(): void {
    if (this.paymentMethod === 'QRIS') {
      this.showQrCode = true;
    } else {
      this.showVaModal = true;
    }
  }

  onCloseQris(val: boolean) {
    this.showQrCode = val;
  }

  onCloseVa(val: boolean) {
    this.showVaModal = val;
  }
}
