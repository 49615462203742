<app-modal-qris [show]="showQrCode" [amount]="totalPrice" [qrUrl]="paymentUrl" (onClose)="onCloseQris($event)"></app-modal-qris>
<app-modal-va
  [show]="showVaModal"
  [amount]="totalPrice"
  [vaBankLogo]="vaBankLogo"
  [vaName]="vaName"
  [vaNumber]="vaNumber"
  (onClose)="onCloseVa($event)"
>
</app-modal-va>

<div class="card card-transaction-history">
  <div class="card-header">
    <div class="date">{{date | date}}</div>
    <div class="status-wrapper">
      <div class="status-text">Status</div>
      <div class="status-item" [ngClass]="status.toLowerCase()">{{status}}</div>
    </div>
  </div>

  <div class="card-body">
    <div class="card-item">
      <div class="card-item-label">ID Transaksi</div>
      <div class="card-item-value">{{transactionId}}</div>
    </div>
    <div class="card-item" *ngIf="promoCode">
      <div class="card-item-label">Promo</div>
      <div class="card-item-value">{{promoCode}}</div>
    </div>
    <div class="card-item">
      <div class="card-item-label">Total</div>
      <div class="card-item-value">
        <span class="before-discount" *ngIf="promoCode">{{totalBeforeDiscount | currency: "Rp ": "symbol" : '1.0-0'}}</span>
        <span class="grand-total" [ngClass]="{'after-discount': promoCode}">{{totalPrice | currency: "Rp ": "symbol" : '1.0-0'}}</span>
      </div>
    </div>
    <div class="card-item">
      <div class="card-item-label">Produk</div>
      <div class="card-item-value">{{productName}}</div>
    </div>
    <div class="card-item">
      <div class="card-item-label">Payment Method</div>
      <div class="card-item-value">{{paymentMethod}}</div>
    </div>

    <div class="card-item btn-wrapper" *ngIf="(status === 'Menunggu' && paymentUrl != null) || status === 'Menunggu' && vaNumber != null">
      <button class="btn btn-main btn-sm" (click)="openPayment()">Bayar</button>
    </div>
  </div>
</div>
