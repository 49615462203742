import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-modal-va',
  templateUrl: './modal-va.component.html',
  styleUrls: ['./modal-va.component.scss']
})
export class ModalVaComponent implements OnChanges {
  @Input() show: boolean = false;
  @Input() vaBankLogo: string = '';
  @Input() amount: number = 0;
  @Input() vaName: string = '';
  @Input() vaNumber: string = '';
  @Output() onClose = new EventEmitter<boolean>();

  @ViewChild('content', { static: false }) private content: any;

  modalReference: any

  constructor(private modalService: NgbModal) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.show) {
      this.modalReference = this.modalService.open(this.content, { centered: true, backdrop: 'static' });
    }
  }

  closeModal() {
    this.modalReference.close();
    this.onClose.emit(false);
  }
}
