<footer class="app-footer">
  <div class="container">
    <hr />
    <div class="inner-section">
      <div class="section-wrapper">
        <div class="logo-wrapper">
          <a [routerLink]="['/']">
            <img
              class="logo"
              src="assets/images/logo.png"
              alt="logo"
            />
          </a>
        </div>
        <div class="sitemap-link-wrapper">
          <ul class="link-list">
            <li class="item">
              <a [routerLink]="['/terms-and-condition']">
                Term & Conditions
              </a>
            </li>
            <li class="item">
              <a [routerLink]="['/privacy-policy']"> Privacy Policy </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="section-wrapper column-reverse">
        <div class="copyright-wrapper">
          <span>PT Bhavana One Teknologi</span>
        </div>
        <div class="social-media-wrapper">
          <a
            [href]="data.instagram_link"
            class="social-media-item instagram"
            target="_blank"
          >
            <img src="/assets/svg/instagram.svg" alt="instagram" />
          </a>
          <a
            [href]="data.whatsapp_link"
            class="social-media-item whatsapp"
            target="_blank"
          >
            <img src="/assets/svg/whatsapp-gray.svg" alt="whatsapp" />
          </a>
        </div>
      </div>
    </div>
  </div>
</footer>
