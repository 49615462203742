<ng-template #content let-modal>
  <div class="default-modal">
    <div class="modal-header">
      <h4 class="modal-title">VA Payment</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
    </div>
    <div class="modal-body">
      <table class="table-payment-detail">
        <tr>
          <td>Bank</td>
          <td>:</td>
          <td><img [src]="vaBankLogo" class="bank-logo"></td>
        </tr>
        <tr>
          <td>VA Name</td>
          <td>:</td>
          <td class="table-value">{{vaName}}</td>
        </tr>
        <tr>
          <td>VA Number</td>
          <td>:</td>
          <td class="table-value">{{vaNumber}}</td>
        </tr>
        <tr>
          <td>Total Payment</td>
          <td>:</td>
          <td class="table-value">Rp {{amount | number: '1.0-0'}}</td>
        </tr>
      </table>
    </div>
  </div>
</ng-template>
