import { Component, OnInit } from '@angular/core';
import { PageHome } from '../../../../api/models/page-home';
import { Setting } from '../../../../api/models/setting';
import { MetaService } from '../../../../services/meta.service';
import { ProductService } from '../../../../api/services/product.service';
import { SettingService } from '../../../../api/services/setting.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  data: Setting = {
    id: 0,
    instagram_link: '',
    whatsapp_link: '',
  };

  constructor(private settingService: SettingService) {}

  ngOnInit(): void {
    this.getSetting();
  }

  getSetting(): void {
    this.settingService.show().subscribe({
      next: (res) => {
        this.data = res.data as Setting;
      },
      error: (e) => {},
    });
  }
}
