import { Component, OnInit } from '@angular/core';
import { Setting } from '../../../api/models/setting';
import { SettingService } from '../../../api/services/setting.service';

@Component({
  selector: 'app-public-layout',
  templateUrl: './public-layout.component.html',
  styleUrls: ['./public-layout.component.scss'],
})
export class PublicLayoutComponent implements OnInit {
  data: Setting = {
    id: 0,
    instagram_link: '',
    whatsapp_link: '',
  };

  constructor(
    private settingService: SettingService,
  ) {}

  ngOnInit(): void {
    this.getSetting();
  }

  getSetting(): void {
    this.settingService.show().subscribe({
      next: (res) => {
        this.data = res.data as Setting;
      },
      error: (e) => {},
    });
  }
}
