<ng-template #content let-modal>
  <div class="default-modal">
    <div class="modal-header">
      <h4 class="modal-title">QR Payment</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
    </div>
    <div class="modal-body">
      <div class="amount">
        Rp {{amount | number: '1.0-0'}}
      </div>
      <img class="qr-image" [src]="qrUrl" alt="qr code" style="width: 100%">
    </div>
  </div>
</ng-template>
